import { useState } from 'react';
import './App.css';
import About from './components/About';
import Home from './components/Home';
import Modal from './components/Modal';
import Slider from './components/Slider';
// import Team from './components/Team';
import Token from './components/Token';

function App() {
  const [show, setShow] = useState(false)
  return (
    <div className='max-w-[100vw] overflow-clip'>
      {
        show ? (
          <>
            <Home />
            <Slider />
            <About />
            {/* <Team/> */}
            <Slider />
            <Token />
            <Slider />
          </>
        ) : (
          <Modal setShow={setShow} />
        )
      }

    </div>
  );
}

export default App;

import React from 'react'

const Token = () => {
    return (
        <div className='bg_main min-h-[70vh] flex flex-col items-center pb-10' >
            <p className='text-[#ffea32] main_font text-[30px] md:text-[70px] py-6 mt-10'>Tokenomics</p>
            <img src={require('../assets/ppptrump.gif')} />

            <p class="bg-black  border-white border-4   lg:w-2/3 mx-auto leading-relaxed text-[20px] p-10 md:text-[30px] text-[white] main_font text-center " >
                 <span className='text-[#CCFF00]'>1 BILLION SUPPLY</span> token with <span className='text-[#CCFF00]'>0% tax</span> and <span className='text-[#ffea32]'>LP Burned</span>,
            </p>
        </div>
    )
}

export default Token
import React from 'react'

const Modal = ({ setShow }) => {
    return (
        <div className='bg_main1 min-h-[100vh] flex flex-col items-center justify-center p-[5%]'>
            <div className=' justify-center items-center flex flex-col'>
                <div onClick={() => { setShow(true) }} className='bg-[#ffffff] border-[4px] border-[#000] main_font p-5 py-2 text-[18px] md:text-[30px]'>
                    Enter
                </div>
                <img className='shake max-w-[900px] w-[100%] max-h-[700px] my-4' src={require('../assets/pppmain.jpg')} />
                {/* <img className='max-w-[900px] w-[90%] my-4' src={require('../assets/pppmain.jpg')} /> */}
                <div className='flex gap-5 py-4'>

                </div>
            </div>
            <img className='absolute w-[300px] right-0 bottom-0 md:right-[16%] md:bottom-[10%]' src={require('../assets/pppzoom.png')} />
        </div>
    )
}

export default Modal
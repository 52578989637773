import React from 'react'

const Home = () => {
    return (
        <div className='bg_main1 min-h-[100vh] flex flex-col items-center pb-6'>
            
            <img className=' max-w-[600px] w-[100%] max-h-[700px]  my-4' src={require('../assets/pppmain.jpg')} />
            <img className=' max-w-[600px] w-[100%] max-h-[700px]  my-4' src={require('../assets/pppzoom.png')} />

            {/* <p className='bg-black px-8 border-white border-4 main_font text-[#ff1313] text-[20px] py-10 text-center md:max-w-[60%] max-w-[90%]'>
PRESIDENT OF THE DIVIDED STATES OF AMERICA            </p> */}
            <div className='flex gap-5 mt-8'>
                <a href='https://twitter.com/'>
                    <div className='bg-[#0e1561] border-[4px] border-[#fff] text-white main_font p-5 py-2 text-[18px] md:text-[30px]'>
                        TWITTER
                    </div>
                </a>
                <a href='https://t.me/'>
                    <div className='bg-[#841111] text-white border-[4px] border-[#fff] main_font p-5 py-2 text-[18px] md:text-[30px]'>
                        TELEGRAM
                    </div>
                </a>
            </div>
           
        </div>
    )
}

export default Home
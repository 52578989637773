import React from 'react'
import Marquee from "react-fast-marquee";

const Slider = () => {
    return (
        <div className='bg-[#000000] w-[120vw] border-y-4 border-[#ffffff] '>
            <Marquee>
                <div className='h-[70px] md:h-[80px] flex justify-center items-center gap-8 md:gap-32'>
                    <div className='flex items-center gap-8'>
                        <p className='military_font text-[40px] text-[#ff3131]'>DIVIDED</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ffffff]'>STATES</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ff3131]'>OF</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#2756ff]'>AMERICA</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ff3131]'>DIVIDED</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ffffff]'>STATES</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ff3131]'>OF</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#2756ff]'>AMERICA</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ff3131]'>DIVIDED</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ffffff]'>STATES</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ff3131]'>OF</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#2756ff]'>AMERICA</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ff3131]'>DIVIDED</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ffffff]'>STATES</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ff3131]'>OF</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#2756ff]'>AMERICA</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ff3131]'>DIVIDED</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ffffff]'>STATES</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#ff3131]'>OF</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        <p className='military_font text-[40px] text-[#2756ff]'>AMERICA</p>
                        <img className='h-[50px]' src={require('../assets/ppptrump.gif')}/>
                        
                        
                    </div>  
                </div>
            </Marquee>
        </div>
    )
}

export default Slider
import React from 'react'

const About = () => {
    return (
        <div className='bg_main2 min-h-[100vh] flex flex-col items-center py-6'>
            <p className='text-[#ff1f1f] main_font text-[40px] md:text-[70px] py-6'>$ DSA</p>
            <img className='w-[600px] md:max-w-[60%] py-6 pb-8' src={require('../assets/pppamer.png')} />
            <img className='w-[600px] md:max-w-[60%] py-6 pb-8' src={require('../assets/pppt2.gif')} />
            <img className='w-[600px] md:max-w-[60%] py-6 pb-8' src={require('../assets/ppptrump.gif')} />
            
            
        </div>
    )
}

export default About